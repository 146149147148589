import atSymbolIcon from "../../public/icons/at-symbol.svg";
import phoneIcon from "../../public/icons/phone.svg";
import type { Language } from "../context/language.tsx";
import { getTranslation } from "../context/translation.ts";
import type { IAboutMe } from "../types/Experience.ts";

const AboutMe = ({
	data,
	language,
}: { data: IAboutMe; language: Language }) => {
	return (
		<div className="rounded-xl border p-5 shadow-lg">
			<h1 className={"my-2 font-extrabold"}>
				{getTranslation("aboutMe", language)}
			</h1>
			<p className={"text-gray-600"}>{data.description}</p>
			<div className="mt-1 flex">
				<div className={"text-blue-700"}>
					<a href={`mailto:${data.email}`}>
						<img
							src={atSymbolIcon}
							width={20}
							height={20}
							className={"mr-1 h-5 inline-block"}
							alt={"Symbol"}
						/>
						{data.email}
					</a>
				</div>
			</div>
			<div className="flex mt-1">
				<div className="text-blue-700">
					<img
						src={phoneIcon}
						width={20}
						height={20}
						className={"mr-1 h-5 inline-block"}
						alt={"Symbol"}
					/>
					<a href={`tel:${data.phoneNumber}`}>{data.phoneNumber}</a>
				</div>
			</div>
			{data.link.map((link, _) => (
				<div key={link.url} className="mt-1 flex text-blue-700">
					<a href={link.url} className={"mr-1 inline-flex h-5 text-blue-600"}>
						<img
							src={`/icons/${link.name}.svg`}
							width={20}
							height={20}
							className={"mr-1 h-5 inline-block"}
							alt={"Symbol"}
						/>
						{link.name.charAt(0).toUpperCase() + link.name.slice(1)}
					</a>
				</div>
			))}
		</div>
	);
};
export default AboutMe;
