import type { Language } from "../context/language.tsx";
import { getTranslation } from "../context/translation.ts";
import type { IExperience } from "../types/Experience.ts";

const ListViewExperience = ({
	data,
	language,
}: { data: Array<IExperience>; language: Language }) => {
	return (
		<div className="border shadow-lg p-5 rounded-xl">
			<h1 className={"font-extrabold my-2"}>
				{getTranslation("experience", language)}
			</h1>
			<div className="space-y-3 divide-y">
				{data.map((item) => (
					<div className="grid grid-cols-6 md:grid-cols-12" key={item.company}>
						<img
							src={item.logo}
							width={0}
							height={0}
							className="rounded-2xl border-gray-100 border h-24 w-24 p-1 md:col-span-2 col-span-2"
							alt={item.company}
						/>
						<div className="p-3 w-full space-y-3 col-span-4 md:col-span-10">
							<div className="flex justify-between">
								<div className="text-gray-900">{item.position}</div>
								<div className="font-medium text-right text-gray-600 inline-flex">
									<div className="text-gray-900">
										<span className="shadow-lg shadow-tamk-500 bg-gradient-to-r from-tamk-600 to-tamk-800 rounded-xl py-2 px-1.5 text-white text-xs">
											{item.type}
										</span>
									</div>
								</div>
							</div>
							<div className="flex md:justify-between flex-wrap gap-2">
								<div className="flex items-center leading-3 text-gray-600 gap-1">
									<img
										src={"icons/briefcase.svg"}
										width={20}
										height={20}
										alt={"Work"}
									/>
									<span className={"text-sm"}>{item.company}</span>
								</div>
								<div className="flex items-center leading-3 text-gray-600 gap-1">
									<img
										src={"icons/location.svg"}
										width={20}
										height={20}
										alt={"Day"}
									/>
									<span className={"text-sm"}>{item.location}</span>
								</div>
								<div className="flex items-center leading-3 text-gray-600 gap-1">
									<img
										src={"icons/tech.svg"}
										width={20}
										height={20}
										alt={"Day"}
									/>
									<span className={"text-sm"}>{item.product}</span>
								</div>
								<div className="font-medium text-right text-gray-600 inline-flex">
									<img
										src={"icons/calendar-days.svg"}
										width={20}
										height={20}
										alt={"Day"}
									/>
									<span className={"pl-1 text-sm"}>{item.duration}</span>
								</div>
							</div>
						</div>
						<ul className="my-2 list-inside col-span-6 md:col-span-12 rounded-lg p-3 pb-4 pl-2 relative overflow-hidden border-l-8 border-tamk-200">
							{item.description.content.map((line: string, index: number) => (
								<li key={index} className="mb-2">
									{line}
								</li>
							))}
							{item.technical && item.technical.length > 0 && (
								<div className="mt-4">
									<h4 className="text-sm font-semibold text-tamk-900 mb-2">
										Technical Skills:
									</h4>
									<div className="flex flex-wrap gap-2">
										{item.technical.map((skill: string, index: number) => (
											<span
												key={index}
												className="bg-tamk-100 text-tamk-800 text-xs font-medium px-2.5 py-0.5 rounded-full"
											>
												{skill}
											</span>
										))}
									</div>
								</div>
							)}
						</ul>{" "}
					</div>
				))}
			</div>
		</div>
	);
};
export default ListViewExperience;
