import type React from "react";

interface SegmentedControlProps {
	options: string[];
	initialSelected?: string;
	onChange: (option: string) => void;
}

const SegmentedControl: React.FC<SegmentedControlProps> = ({
	options,
	initialSelected,
	onChange,
}) => {
	return (
		<div className="flex border border-gray-300 rounded-lg p-[1px]">
			{options.map((option) => (
				<button
					key={option}
					className={`flex-1 py-2 text-center rounded-md transition-colors duration-200 ${
						initialSelected === option
							? "bg-tamk-500 text-white"
							: "bg-white text-tamk-500 hover:bg-gray-100"
					}`}
					onClick={() => onChange(option)}
					type="button"
					disabled={initialSelected === option}
				>
					{option}
				</button>
			))}
		</div>
	);
};

export default SegmentedControl;
