import "./main.css";
import AboutMe from "./components/AboutMe.tsx";
import Avatar from "./components/Avatar.tsx";
import Certificates from "./components/Certificates.tsx";
import Educations from "./components/Educations.tsx";
import ListViewExperience from "./components/ListViewExperience.tsx";
import Skills from "./components/Skills.tsx";
import { LanguageProvider, useLanguage } from "./context/language.tsx";
import dataJson from "./data.json";

const MainContent = () => {
	const { language } = useLanguage();
	const data = dataJson[language];
	return (
		<div className="mx-2 my-4 grid grid-cols-1 gap-4 lg:mx-12 lg:my-2 lg:grid-cols-3 xl:mx-10">
			<div className="space-y-5">
				<Avatar data={data.avatar} language={language} />
				<Skills data={data.skill} language={language} />
				<Certificates data={data.certificates} language={language} />
				<Educations data={data.education} language={language} />
			</div>
			<div className="space-y-5 lg:col-span-2">
				<AboutMe data={data.aboutMe} language={language} />
				<ListViewExperience data={data.experience} language={language} />
			</div>
		</div>
	);
};

export function Main() {
	return (
		<LanguageProvider>
			<MainContent />
		</LanguageProvider>
	);
}
