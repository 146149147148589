import { useState } from "react";
import { type Language, useLanguage } from "../context/language.tsx";
import { getTranslation } from "../context/translation.ts";
import type { IAvatar } from "../types/Experience.ts";
import Segmented from "./ui/Segmented.tsx";

const Avatar = ({ data, language }: { data: IAvatar; language: Language }) => {
	const [currentLanguage, setCurrentLanguage] = useState<Language>(language);
	const { changeLanguage } = useLanguage();

	const handleSegmentChange = (selected: string) => {
		const newLanguage = selected === "Eng" ? "en" : "vi";
		setCurrentLanguage(newLanguage);
		changeLanguage(newLanguage);
	};

	return (
		<div className="border shadow-lg p-5 rounded-xl">
			<div className="space-y-3 divide-y divide-dotted flex">
				<div className="justify-center object-center items-center text-left my-1">
					<img
						src={data.image}
						alt={data.alt}
						width={100}
						height={100}
						className={"rounded-xl border-gray-200 border"}
					/>
					<span>
						{data.name}
						<span className={"ml-1 text-gray-500"}>({data.nickname})</span>
					</span>
					<div className="my-2">
						<Segmented
							options={["Eng", "Viet"]}
							onChange={handleSegmentChange}
							initialSelected={currentLanguage === "en" ? "Eng" : "Viet"}
						/>
					</div>
					<span className={"text-gray-500 font-light text-base"}>
						{data.position}
					</span>
				</div>
			</div>
			<div className="space-y-3 divide-y divide-dotted my-2">
				<div className="flex justify-between">
					<div className="text-gray-600">
						{getTranslation("experience", language)}
					</div>
					<div className="font-medium text-right text-gray-600">
						{data.experience}
					</div>
				</div>
				{/*<div className="flex justify-between pt-2">*/}
				{/*	<div className="text-gray-600">{data.more.birthday.title}</div>*/}
				{/*	<div className="font-medium text-right text-gray-600">*/}
				{/*		{data.more.birthday.value}*/}
				{/*	</div>*/}
				{/*</div>*/}
				{/*<div className="flex justify-between pt-2">*/}
				{/*	<div className="text-gray-600">{data.more.location.title}</div>*/}
				{/*	<div className="font-medium text-right text-gray-600">*/}
				{/*		{data.more.location.value}*/}
				{/*	</div>*/}
				{/*</div>*/}
			</div>
		</div>
	);
};

export default Avatar;
