import type { Language } from "./language.tsx";

type TranslationKey =
	| "aboutMe"
	| "educations"
	| "experience"
	| "skills"
	| "certificates";

type TranslationDictionary = {
	[key in TranslationKey]: string;
};

type Translations = {
	[lang in Language]: TranslationDictionary;
};

const translations: Translations = {
	en: {
		aboutMe: "About Me",
		educations: "Educations",
		experience: "Experience",
		skills: "Skills",
		certificates: "Certificates",
	},
	vi: {
		aboutMe: "Về tôi",
		educations: "Học vấn",
		experience: "Kinh nghiệm",
		skills: "Kỹ năng",
		certificates: "Chứng chỉ",
	},
};

export const getTranslation = (key: TranslationKey, lang: Language): string => {
	return translations[lang][key] || key;
};
