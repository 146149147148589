import type { Language } from "../context/language.tsx";
import { getTranslation } from "../context/translation.ts";
import type { ISkill } from "../types/Experience.ts";

const Skills = ({ data, language }: { data: ISkill[]; language: Language }) => {
	return (
		<>
			<div className="border shadow-lg p-5 rounded-xl">
				<h1 className={"font-extrabold my-2"}>
					{getTranslation("skills", language)}
				</h1>

				<div className="space-y-3 divide-y divide-dotted">
					{data.map((skill) => (
						<div key={skill.title} className="flex justify-between">
							<div className="text-gray-600 font-light">{skill.title}</div>
							<div className="font-medium text-right text-gray-800">
								{skill.items.map((item, index) => (
									<span key={item.name} className="items-center">
										{item.name}
										{index < skill.items.length - 1 && ", "}
									</span>
								))}
							</div>
						</div>
					))}
				</div>
			</div>
		</>
	);
};

export default Skills;
