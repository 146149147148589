import type React from "react";
import {
	type ReactNode,
	createContext,
	useContext,
	useEffect,
	useState,
} from "react";

export type Language = "en" | "vi";

interface LanguageContextType {
	language: Language;
	changeLanguage: (lang: Language) => void;
}

const LanguageContext = createContext<LanguageContextType | undefined>(
	undefined,
);

export const useLanguage = (): LanguageContextType => {
	const context = useContext(LanguageContext);
	if (context === undefined) {
		throw new Error("useLanguage must be used within a LanguageProvider");
	}
	return context;
};

interface LanguageProviderProps {
	children: ReactNode;
}

export const LanguageProvider: React.FC<LanguageProviderProps> = ({
	children,
}) => {
	const [language, setLanguage] = useState<Language>(() => {
		const savedLanguage = localStorage.getItem("language");
		return (savedLanguage as Language) || "en";
	});

	useEffect(() => {
		localStorage.setItem("language", language);
	}, [language]);

	const changeLanguage = (lang: Language) => {
		setLanguage(lang);
	};

	return (
		<LanguageContext.Provider value={{ language, changeLanguage }}>
			{children}
		</LanguageContext.Provider>
	);
};
